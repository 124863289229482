<template>
    <div>
        <highcharts v-if="loaded" :options="chartOptions" ref="chart"/>
        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import Highcharts from 'highcharts'
    import exportingInit from "highcharts/modules/exporting"
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'

    exportingInit(Highcharts)

    export default {
        name: 'ScreenedByTrackersChart',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart
        },
        data() {
            return {
                loaded: false,
                chartName: "StartupsScreenedByTrackers",
                chartData: [],
                chartOptions: {
                    chart: {
                        type: 'column',
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: 'Companies <span style="color:#0083b2">Identified</span> / <span style="color:#1e7d3f">Screened</span>',
                        align: 'left'
                    },
                    plotOptions: {
                        column: {
                            stacking: "normal",
                        },
                        series: {
                            "borderWidth": 0,
                            "dataLabels": {
                                "enabled": true,
                                "style": {
                                    "textShadow": false,
                                    "fontSize": "10px"
                                }
                            }
                        },
                    },
                    xAxis: {
                        categories: []
                    },
                    yAxis: {
                        type: "logarithmic",
                        title: {
                            text: 'Companies count'
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                color: ( // theme
                                    Highcharts.defaultOptions.title.style &&
                                    Highcharts.defaultOptions.title.style.color
                                ) || 'gray',
                                textOutline: 'none'
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: '<b>{point.x}</b><br/>',
                        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                    },
                    series: [{
                        name: 'Screened',
                        color: "#1e7d3f",
                        data: []
                    }, {
                        name: 'Identified',
                        color: "#0083b2",
                        data: []
                    }]
                }
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData('StartupsScreenedByTrackers').then(response => {
                    this.chartData = response.data
                    this.formatAndSetChartData()

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },
            formatAndSetChartData() {
                let categories = []
                let screened = []
                let identified = []

                this.chartData.forEach(item => {
                    categories.push(item.name)
                    screened.push(item.screened)
                    identified.push(item.identified)
                })

                this.chartOptions.xAxis.categories = categories

                this.chartOptions.series[0].data = screened
                this.chartOptions.series[0].events = {
                    click: (e) => {
                        this.openStartupsModal(e.point.category, 'screened')
                    }
                }

                this.chartOptions.series[1].data = identified
                this.chartOptions.series[1].events = {
                    click: (e) => {
                        this.openStartupsModal(e.point.category, 'identified')
                    }
                }

                // this.$refs.chart.chart.xAxis[0].setCategories(categories, false)
                // this.$refs.chart.chart.series[0].setData(screened, false)
                // this.$refs.chart.chart.series[1].setData(identified, false)
                // this.$refs.chart.chart.redraw()
            },

            openStartupsModal(tracker, category) {
                this.$emit('showStartups', [this.chartName, `Startups ${category} in tracker "${tracker}"`, JSON.stringify([tracker, category])])
            }
        }
    }
</script>
